.selectProject {
  font-size: calc(12px + 0.5vw) !important;
  font-weight: 500;
}

.imageThumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.thumbnailCustom {
  width: 100%;
  height: 100%;

  background-size: cover;
  @media screen and (min-width: 568px) and (max-width: 991px) {
    height: calc(27vw);
  }
  @media screen and (max-width: 567px) {
    height: calc(52vw);
  }
}
