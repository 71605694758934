.pagePdf {
  width: auto;
  border: 2px solid black;
}

.pdfWrapper {
  border: 2px solid black;
  p {
    text-align: center;
  }
}
.buttonActionWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > p {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 2em;
  }
}
.centerPageNumbers {
  text-align: center;
  font-weight: bolder;
  font-size: 3vw !important;
  margin-top: 100px;
}
.downloadButton {
  font-size: 1.2em;
  background-color: #0b689a;
  font-weight: bold;
  width: 200px;
  height: 100px;
  border-radius: 5%;
  border: none;
  color: white;
  &:hover {
    background-color: #1d91cf;
  }
}
.oneDriveIcon {
  height: 60%;
  margin: auto;
}
