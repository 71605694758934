.pictureWrap {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin: auto;
  width: 80%;
  min-width: 300px;
  max-width: 1690px;
  min-height: 300px;
  padding: 5% 0;

  @media screen and (max-width: 576px) {
    width: 92%;
  }
  //   @include media("<tablet") {
  //     width: 92%;
  //   }
}

.rowCustom {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: center;
}
.columnCustom {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 40%;
  padding: 0 10px;

  @media screen and (min-width: 576px) and (max-width: 991px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  //   @include media("<desktop") {
  //     -ms-flex: 50%;
  //     flex: 50%;
  //     max-width: 50%;
  //   }
  //   @include media("<tablet") {
  //     -ms-flex: 100%;
  //     flex: 100%;
  //     max-width: 100%;
  //   }
  @media screen and (max-width: 576px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.galleryPic {
  width: 100%;
  margin-top: 8px;
  vertical-align: center;
}

.ownStyleMediaContainer {
  margin: auto;
  width: clamp(280px, 63vw, 1110px);
}
.simplePicturesContainer {
  max-width: 741px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.grayThumbnail {
  filter: grayscale(80%) brightness(0.8);
  &:hover {
    filter: grayscale(6%) brightness(0.9);
  }
}

.textToView {
  font-size: calc(20px + 0.5vw) !important;
  line-height: calc(17px + 1vw) !important;
}

.theCards {
  page-break-inside: avoid;
  break-inside: avoid;
  column-count: 2;
  column-gap: 15px;
}
.theCardsThree {
  @extend .theCards;
  column-count: 3;
}
.theCardsItem {
  // color: white;

  // margin: auto;

  margin-bottom: 15px;
  position: relative;
  break-inside: avoid;
  width: 100%;
  will-change: transform;

  border-radius: 4px;
  overflow: hidden;
  box-shadow: 3px 3px 5px -3px rgb(255 255 255 / 30%);
}
.picItem {
  width: 100%;
}

/* Screen larger than 600px? 2 column */
@media (min-width: 300px) {
  .theCards {
    column-count: 1;
  }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 700px) {
  .theCardsOne {
    column-count: 1;
  }
  .theCards {
    column-count: 2;
  }
  .theCardsThree {
    column-count: 3;
  }
}
