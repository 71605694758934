// All Css Here
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "node_modules/include-media/dist/include-media";

body > iframe {
  display: none !important;
}

.marginerMiddle {
  display: table;
  margin-left: auto;
  margin-right: auto;
  max-width: 741px;
}
.inner {
  h4 {
    font-size: calc(17px + 0.5vw) !important;
    line-height: calc(17px + 1vw) !important;
  }
}

.bolderCategory {
  font-weight: 500;
}
.mobileFriendly {
  width: 250px;
}
.dflex {
  display: flex;
}
.justify-center {
  justify-content: center;
}

.specialStyle {
  width: 73px !important;
  height: 70px !important;
  margin-left: -33px !important;
  margin-top: -41px !important;
}
.flexColumn {
  flex-direction: column;
}

.piutzyke {
  color: red;
}
.emptyContainer {
  width: 100%;
  height: 100%;
  min-height: 230px;
}
.userNoSelect {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.cursorPointer {
  cursor: pointer;
}

.attentionPrice {
  color: rgb(0, 172, 0);
}

.popUpDani {
  @include media("<tablet") {
    max-height: 200px;
  }
  @include media(">=tablet", "<desktop") {
    max-height: 400px;
  }
}
.socialContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.onlyOnDesktop {
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.onlyOnTabletMobile {
  @media screen and (min-width: 991px) {
    display: none;
  }
}
