/* Previe Styles  */

.main-wrapper {
  position: relative;
  background: #ffffff;
}
.prv-header-area {
  padding: 18px 85px;
  padding-top: 18px;
}
.fullscreen {
  &.empty-div {
    @media #{$md-layout} {
      display: none;
    }
    @media #{$lg-layout} {
      display: none;
    }
  }
}

.with-particles {
  position: relative;
  z-index: 3;
  .frame-layout__particles {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    @media #{$large-mobile} {
      display: none;
    }
  }
  .inner {
    position: relative;
    z-index: 3;
  }
}

.gradient-overlay {
  position: relative;
}

.gradient-overlay::before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+7,000000+100&0+50,0.5+100 */
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0) 7%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0) 7%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 7%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.prv-banner-wrapper {
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  @media #{$sm-layout} {
    min-height: auto;
    position: static;
    padding: 60px 0;
    background-image: none !important;
    background-color: #f6f6f6;
  }
  @media #{$md-layout} {
    min-height: auto;
    position: static;
    padding: 80px 0;
    background-image: none !important;
    background-color: #f6f6f6;
  }
  @media #{$lg-layout} {
    min-height: auto;
    position: static;
    padding: 80px 0;
    background-image: none !important;
    background-color: #f6f6f6;
  }
  @media #{$md-layout} {
  }
  @media #{$lg-layout} {
  }
  .inner {
    padding: 35px;
    position: relative;
    z-index: 1;
    @media #{$laptop-device} {
      padding: 35px 0;
    }
    @media #{$sm-layout} {
      padding: 0;
    }
    .logo {
      img {
        max-height: 70px;
      }
    }
    .title {
      font-size: 23px;
      color: #515462;
      line-height: 42px;
      margin: 30px 0 50px 0;
      @media #{$laptop-device} {
        font-size: 20px;
        line-height: 36px;
        margin: 24px 0 40px 0;
      }
      @media #{$sm-layout} {
        margin: 32px 0 20px 0;
      }
    }
    .purshase-btn {
      margin-left: -15px;
      margin-right: -15px;
      @media #{$laptop-device} {
        margin-left: -10px;
        margin-right: -10px;
      }
      @media #{$sm-layout} {
        margin-left: -5px;
        margin-right: -5px;
      }
      a {
        margin-left: 15px;
        margin-right: 15px;
        @media #{$sm-layout} {
          margin-left: 5px;
          margin-right: 5px;
        }
        &.rn-button-style--2 {
          @media #{$laptop-device} {
            padding: 10px 25px;
          }
          @media #{$sm-layout} {
            padding: 9px 12px;
            font-size: 14px;
          }
        }
        @media #{$laptop-device} {
          margin-left: 10px;
          margin-right: 10px;
        }
        & + a {
          @media #{$small-mobile} {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

/*---------------------
    Demo Area  
-----------------------*/

.home-demo-area {
  .wrapper {
    @media #{$md-layout} {
      padding: 0 30px !important;
    }
    @media #{$sm-layout} {
      padding: 0 30px !important;
    }
  }
  .section-title {
    &.text-white {
      h2 {
        color: #c6c9d8;
        margin-bottom: 9px;
      }
      p {
        color: #c6c9d8;
        opacity: 0.75;
        font-weight: 300;
      }
    }
  }
}
// Start Single Demo
.bg_color--7 {
  .single-demo {
    position: relative;
    a {
      > span {
        display: block;
        text-align: center;
        padding: 20px 0;
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
        font-weight: 500;
        transition: 0.3s ease-in-out;
        letter-spacing: 0.2px;
      }
    }
  }
}
.single-demo {
  padding: 0 30px;
  margin: 45px 0;

  @media #{$laptop-device} {
    padding: 0px;
  }
  @media #{$md-layout} {
    padding: 0px;
  }
  @media #{$sm-layout} {
    padding: 0px;
    margin: 20px 0;
  }
  @media #{$lg-layout} {
    padding: 0px;
  }
  a {
    display: block;
    @extend %transition;
    position: relative;
    z-index: 2;
    img {
      box-shadow: 3px 4px 20px 0 rgba(0, 0, 0, 0.1);
      transition: 0.7s cubic-bezier(0.26, 0.76, 0.3, 0.75);
      width: 100% !important;
      border-radius: 6px;
    }
    .title {
      display: block;
      text-align: left;
      padding: 25px 0 10px 0;
      color: #1f1f25;
      font-size: 20px;
      font-weight: 500;
      transition: 0.3s ease-in-out;
      letter-spacing: 0.2px;
      margin: 0;
    }
    .info {
      display: block;
      padding: 0;
      color: rgba(29, 29, 36, 0.75);
      font-size: 14px;
      font-weight: 300;
      transition: 0.3s ease-in-out;
      line-height: 25px;
    }
    > span {
      &.number {
        display: none;
        position: absolute;
        color: #f6f6f6;
        z-index: -1;
        font-size: 81px;
        bottom: -55px;
        line-height: inherit;
        font-weight: 300;
        letter-spacing: 11px;
      }
    }
    &:hover {
      img {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
        box-shadow: 0 50px 80px -10px rgba(0, 0, 0, 0.17);
      }
    }
    .label-new {
      position: absolute;
      top: 5px;
      background: #f81f01;
      background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f81f01', endColorstr='#ee076e',GradientType=1 );
      padding: 9px 14px;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1;
      right: 5px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  }
}

/*-------------------
Faq Area  
----------------------*/
.pv-feaq-area {
  .accodion-style--1 .accordion__item + .accordion__item {
    margin-top: 20px;
  }
  .accodion-style--1 .accordion__item .accordion__heading .accordion__button {
    font-size: 18px;
    padding: 10px 0;
  }
  .accodion-style--1 .accordion__item .accordion__heading {
    width: 100%;
  }
  .accodion-style--1 .accordion__item .accordion__heading .accordion__button::after {
    width: 100%;
  }
  .accodion-style--1 .accordion__item .accordion__heading .accordion__button::after {
    height: 1px;
  }
}

/*-------------------
    Tab BUtton  
---------------------*/

.tablist-inner {
  display: inline-block;
}
.pv-tab-button {
  display: flex;
  justify-content: center;
  list-style: none;
  background: rgba(255, 26, 80, 0.03);
  min-width: 570px;
  margin: 0 auto;
  border-radius: 6px;
  margin-bottom: 30px;
  margin-top: 35px;
  flex-wrap: wrap;
  padding: 0;
  padding: 0 36px;
  outline: none;
  font-weight: 600;
  @media #{$sm-layout} {
    margin-bottom: 18px;
    margin-top: 5px;
    padding: 8px 15px;
  }
  @media #{$md-layout} {
    padding: 0 15px;
  }
  @media #{$sm-layout} {
    min-width: auto;
  }
  .label {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
    }
  }
  li {
    padding: 0 14px;
    color: #101010;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    @media #{$large-mobile} {
      padding: 0 11px;
    }
    span {
      position: relative;
      display: block;
      padding: 20px 0;
      cursor: pointer;
      @media #{$large-mobile} {
        padding: 11px 0;
      }
      &::after {
        position: absolute;
        left: 0;
        background: $theme-color;
        bottom: 0;
        height: 3px;
        content: "";
      }
    }
    &.react-tabs__tab--selected {
      span {
        color: $theme-color;
        &::after {
          width: 100%;
        }
      }
    }
  }
}

/*-----------------------
Footer Style  
-------------------------*/
.prv-footer {
  .inner {
    h2 {
      &.title {
        color: $heading-color;
        font-size: 60px;
        font-weight: 500;
        margin-bottom: 65px;
      }
    }
  }
}
a {
  &.purchase-btn {
    display: inline-block;
    padding: 0 75px;
    height: 70px;
    line-height: 70px;
    border-radius: 43px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.4s ease-in-out;
    color: #ffffff;
    background: $heading-color;
    border: 1px solid $heading-color;
    &:hover {
      color: $heading-color;
      border: 1px solid $heading-color;
      background: transparent;
    }
  }
}

/* Feature Area */
.prv-feature {
  .content {
    h3 {
      &.title {
        color: #c6c9d8;
        @media #{$md-layout} {
          margin-bottom: 13px;
          font-size: 20px;
        }
        @media #{$sm-layout} {
          margin-bottom: 13px;
          font-size: 20px;
        }
      }
    }
    p {
      &.subtitle {
        color: #c6c9d8;
        @media #{$md-layout} {
          font-size: 16px;
          line-height: 28px;
        }
        @media #{$laptop-device} {
          font-size: 16px;
          line-height: 26px;
        }
        @media #{$sm-layout} {
          font-size: 16px;
          line-height: 28px;
          color: #ffffff;
        }
      }
    }
  }
}

.text-white-wrapper {
  a {
    &.rn-button-style--2 {
      border: 2px solid #fff;
      color: #fff;
    }
  }
}
