.catchyPhrase {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  text-align: center;
  font-size: calc(12px + 0.5vw);
  line-height: calc(19px + 0.5vw);

  padding: 0 44px;
}
